import api from '@/api/index'
import request from '@/utils/request'

/**
 * 企业画像-信用数据总览
 * @param data
 */
export function getCreditData (data) {
  return request({
    url: api.GetCreditData,
    method: 'get',
    params: data,
    hideloading: false
  })
}

/**
 * 企业画像-行业平均水平
 * @param data
 */
export function getIndustryLevelAvg (data) {
  return request({
    url: api.GetIndustryLevelAvg,
    method: 'get',
    params: data,
    hideloading: false
  })
}

/**
 * 企业画像-企业发展情况
 * @param data
 */
export function getCompanyDevelopStatus (data) {
  return request({
    url: api.GetCompanyDevelopStatus,
    method: 'get',
    params: data,
    hideloading: false
  })
}

/**
 * 企业画像-近五年创新能力
 * @param data
 */
export function getCompanyInnovateAbility (data) {
  return request({
    url: api.GetCompanyInnovateAbility,
    method: 'get',
    params: data,
    hideloading: false
  })
}

/**
 * 企业画像-近五年客户行业分布
 * @param data
 */
export function getCompanyCustomerIndustryDistribution (data) {
  return request({
    url: api.GetCompanyCustomerIndustryDistribution,
    method: 'get',
    params: data,
    hideloading: false
  })
}

/**
 * 企业画像-近五年中标趋势
 * @param data
 */
export function getCompanyAchievement (data) {
  return request({
    url: api.GetCompanyAchievement,
    method: 'get',
    params: data,
    hideloading: false
  })
}
/**
 * 企业画像-市场扩展分布
 * @param data
 */
export function getCompanyMarketExtension (data) {
  return request({
    url: api.GetCompanyMarketExtension,
    method: 'get',
    params: data,
    hideloading: false
  })
}
